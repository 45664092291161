import React, { useState } from 'react';
import { Alert, Button, Card, CardContent, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { Exercise } from '../../../types';
import { useFieldArray, useForm } from 'react-hook-form';
import { omit, pick } from 'lodash';
import { useApi } from '../../../lib/api';
import { useLoading } from '../../../lib/hooks';
import { Blocker } from '../../../components/Blocker';
import { StepCard } from './StepCard';
import { ExerciseFormData } from './types';
import { formStateToInput, inputToFormState } from './StepCard/utils';

type ExerciseFormProps = {
  selectedExercise?: Exercise;
  onSave: (exercise: Exercise) => void;
  levelId: string;
};

export const ExerciseForm: React.FC<ExerciseFormProps> = ({ selectedExercise, onSave, levelId }) => {
  const { register, handleSubmit, formState: { errors }, reset, control, watch, setValue } = useForm<ExerciseFormData>({
    defaultValues: selectedExercise?._id ? omit(inputToFormState(selectedExercise) ?? {}, ['_id', 'group']) : undefined,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'steps',
    shouldUnregister: true,
  });
  const [error, setError] = useState<string | null>(null);
  const { post } = useApi();

  const [handleExerciseSave, loading] = useLoading(async (data: ExerciseFormData) => {
    try {
      const input = formStateToInput(data);
      if (selectedExercise?._id) {
        const updated = await post(`/admin/exercise/${selectedExercise._id}/edit`, {
          ...pick(input, ['name', 'description']),
          steps: JSON.stringify(input.steps ?? []),
        });
        reset();
        if (updated?.data) {
          onSave(updated.data as Exercise);
        }
      } else {
        const subject = await post('/admin/exercise/create', {
          ...pick(input, ['name', 'description']),
          steps: JSON.stringify(input.steps ?? []),
          level:  levelId,
        });
        reset();

        if (subject?.data) {
          onSave(subject?.data as Exercise);
        }
      }
    } catch (e) {
      setError('Something went wrong');
    }
  }, [levelId, onSave, selectedExercise?._id]);

  const watchStepsArray = watch('steps');
  const controlledSteps = fields.map((field, index) => {
    return {
      ...field,
      ...watchStepsArray?.[index] ?? {}
    };
  });

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit(handleExerciseSave)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">
                {selectedExercise?.name ? `Edit exercise: ${selectedExercise?.name}` : 'Exercise'}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                {...register('name', { required: true })}
                size="medium"
                variant="standard"
                label="Name"
                sx={{ width: '100%' }}
                error={!!errors.name}
              />
            </Grid>
            <Grid item>
              <TextField
                {...register('description')}
                size="medium"
                variant="standard"
                label="Description"
                sx={{ width: '100%' }}
                multiline
                error={!!errors.description}
              />
            </Grid>
            {controlledSteps.map((field, index) => (
              <Grid item key={field.id}>
                <StepCard
                  {...field}
                  register={register}
                  index={index}
                  control={control}
                  removeStep={() => remove(index)}
                  setValue={setValue}
                />
              </Grid>
            ))}
            <Grid item>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={() => append({
                  type: 'math',
                  content: {
                    ops: [],
                    variants: [],
                    right: '',
                  },
                })}
              >
                Add step
              </Button>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
        <Blocker loading={loading} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!!error}
          onClose={() => setError(null)}
          autoHideDuration={6000}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};
