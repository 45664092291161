import React, { useEffect } from 'react';
import { CircularProgress, css, Grid } from '@mui/material';
import { useAuth } from '../../lib/providers/AuthProvider';

export const LogoutPage: React.FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      css={css`
          height: 100%;
      `}
      justifyContent="center"
      alignItems="center"
      container
    >
      <CircularProgress size={64} />
    </Grid>
  );
};
