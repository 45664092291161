import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, FormLabel, Chip, Typography, Snackbar, Alert } from '@mui/material';
import { Control, useFieldArray, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { ExerciseFormData } from '../../types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Blocker } from '../../../../../components/Blocker';
import { useApi } from '../../../../../lib/api';
import { useLoading } from '../../../../../lib/hooks';

type ListeningTestConstructorProps = {
  control: Control<ExerciseFormData>;
  register: UseFormRegister<ExerciseFormData>;
  path: string;
  setValue: UseFormSetValue<ExerciseFormData>;
  audioUrl?: string;
};

export const ListeningTestConstructor: React.FC<ListeningTestConstructorProps> = ({ control, register, path, setValue, audioUrl }) => {
  const { fields: variants, append: addVariant, remove: removeVariant } = useFieldArray({
    control,
    name: `${path}.variants` as 'steps',
    shouldUnregister: true,
  });

  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const { post } = useApi();

  const [handleUpload, uploading] = useLoading(async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('category', 'audio');
      const res = await post('/admin/files/upload', formData);
      if (res?.data?.filePath) {
        setValue(`${path}.audioUrl` as 'steps', res.data.filePath);
      } else {
        setError('Uploading error');
      }
    } catch (e) {
      console.error(e);
      setError('Uploading error');
    }
  }, [path]);

  useEffect(() => {
    if (file) {
      handleUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          {...register(`${path}.text` as 'steps', { required: true })}
          size="medium"
          variant="standard"
          label="Text"
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item container justifyContent="center" alignItems="center">
        {audioUrl ? (
          <Chip icon={<CheckCircleIcon />} label="Audio uploaded" color="success" sx={{ mr: 2 }} />
        ) : (
          <Typography
            variant="caption"
            sx={(theme) => ({
              mr: 2,
            })}
          >
            Please select audio file
          </Typography>
        )}
        <Button variant="contained" component="label" color="secondary">
          Upload audio
          <input
            hidden
            accept="audio/*"
            multiple
            type="file"
            onChange={(event) => setFile(event.target.files?.[0])}
          />
        </Button>
      </Grid>
      <Grid item>
        <TextField
          {...register(`${path}.speed` as 'steps', { required: true })}
          size="medium"
          variant="standard"
          label="Speed"
          sx={{ width: '100%' }}
          type="number"
        />
      </Grid>
      <Blocker loading={uploading} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!error}
        onClose={() => setError(null)}
        autoHideDuration={6000}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>

      <Grid item>
        <FormLabel>
          Variants
        </FormLabel>
      </Grid>
      <Grid item container spacing={2}>
        {variants.map((field, index) => (
          <Grid item key={field.id} sm={2}>
            <TextField
              {...register(`${path}.variants.${index}.value` as 'steps.0.type')}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => addVariant({ value: '' } as any)}
          type="button"
        >
          Insert variant
        </Button>
        {variants.length > 0 && (
          <Button
            variant="outlined"
            color="warning"
            onClick={() => removeVariant(variants.length - 1)}
            type="button"
          >
            Remove last variant
          </Button>
        )}
      </Grid>
      <Grid item>
        <TextField
          label="Correct answer"
          {...register(`${path}.right` as 'steps.0.type')}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
};
