import React from 'react';
import { GlobalStyles, css, AppBar, Typography, Toolbar, Box, IconButton, Grid } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import { useAuth } from '../../lib/providers/AuthProvider';
import { Blocker } from '../Blocker';

export const MainLayout: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const navigate = useNavigate();
  const { logout, token, loading } = useAuth();
  return (
    <>
      <GlobalStyles styles={css`
        * {
          box-sizing: border-box;
        }
        body, html, #root {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          background-color:#f5f6fa;
        }
        a {
          text-decoration: none;
        }
      `} />
      {!!token && (
        <AppBar position="fixed">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              css={css`
              cursor: pointer;
            `}
              onClick={() => navigate('/')}
            >
              KIDD
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <IconButton
                size="large"
                edge="end"
                onClick={logout}
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      {loading ? (
        <Blocker loading />
      ) : (
        <Grid
          css={(theme) => css`
          height: 100%;
          padding: ${theme.spacing(10, 2, 2)};
        `}
        >
          {children}
        </Grid>
      )}
    </>
  )
};
