import React from 'react';
import { css, LinearProgress } from '@mui/material';

export const Blocker: React.FC<{ loading: boolean }> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.65);
      `}
    >
      <LinearProgress
        css={css`
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        `}
      />
    </div>
  )
};
