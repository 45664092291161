import axios, { AxiosError } from 'axios';
import { config } from '../../config';
import { useAuth } from '../providers/AuthProvider';
import { useCallback } from 'react';

export const useApi = () => {
  const { token, logout } = useAuth();
  const post = useCallback(async <T>(url: string, data: T) => {
    try {
      const response = await axios.post(`${config.api}${url}`, data, {
        headers: {
          'x-access-token': token ?? '',
        },
      });
      return response;
    } catch (e) {
      if (e && e instanceof AxiosError && ['Invalid Token', 'A token is required for authentication'].includes(e.response?.data?.error ?? '')) {
        logout?.();
        return;
      }
      throw e;
    }
  }, [token, logout]);

  const get = useCallback(async <T>(url: string, data: T) => {
    try {
      const requestUrl = new URL(`${config.api}${url}`);
      Object.entries(data ?? {}).forEach(([key, value]) => {
        requestUrl.searchParams.append(key, `${value}`);
      });
      const response = await axios.get(requestUrl.toString(), {
        headers: {
          'x-access-token': token ?? '',
        },
      });
      return response;
    } catch (e) {
      if (e && e instanceof AxiosError && ['Invalid Token', 'A token is required for authentication'].includes(e.response?.data?.error ?? '')) {
        logout?.();
        return;
      }
      throw e;
    }
  }, [token, logout]);

  return {
    post,
    get,
  };
};

