import { DependencyList, useCallback, useState } from 'react';

export const useLoading = <T extends Array<any>, K>(fn: (...args: T) => Promise<K>, deps: DependencyList) => {
  const [loading, setLoading] = useState(false);
  const handleLoading = useCallback(async (...args: T) => {
    try {
      setLoading(true);
      await fn(...args);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  return [handleLoading, loading] as const;
};
