import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardContent,
  css,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useAuth, UserSession } from '../../lib/providers/AuthProvider';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import { config } from '../../config';
import { useLoading } from '../../lib/hooks';
import { Blocker } from '../../components/Blocker';

type LoginFormData = {
  email: string;
  password: string;
};

type LoginResponse = {
  data: Omit<UserSession, 'id'> & { _id: string };
}

export const LoginPage: React.FC = () => {
  const { login } = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>();
  const [error, setError] = useState<string | null>(null);

  const [handleLogin, loading] = useLoading(async (data: LoginFormData) => {
    try {
      const response = await axios.post<LoginFormData, LoginResponse>(`${config.api}/auth/signin`, data);
      if (response.data?.token) {
        const { _id, name, token, email } = response.data;
        login?.({ id: _id, name, token, email });
      } else {
        setError('Internal error');
      }
    } catch (e) {
      if (e && e instanceof AxiosError) {
        setError(e.response?.data?.error ?? null);
      }
    }
  }, []);

  return (
    <Grid
      css={css`
          height: 100%;
        `}
      justifyContent="center"
      alignItems="center"
      container
    >
      <Card
        css={css`
          min-width: 300px;
          position: relative;
        `}
      >
        <CardContent>
          <Typography variant="h4">
            Login
          </Typography>
          <Divider
            css={(theme) => css`
              margin: ${theme.spacing(2, 0)};
            `}
          />
          <Typography variant="caption">Please enter your credentials to enter our system</Typography>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Grid direction="column" container spacing={2} sx={{ mt: 2 }}>
              <Grid item>
                <TextField
                  {...register('email', { required: true })}
                  size="medium"
                  variant="standard"
                  label="Email"
                  sx={{ width: '100%' }}
                  error={!!errors.email}
                />
              </Grid>
              <Grid item>
                <TextField
                  {...register('password', { required: true })}
                  size="medium"
                  variant="standard"
                  label="Password"
                  type="password"
                  sx={{ width: '100%' }}
                  error={!!errors.password}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  Log in
                </Button>
              </Grid>
            </Grid>
          </form>
          <Blocker loading={loading} />
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!error}
        onClose={() => setError(null)}
        autoHideDuration={6000}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
