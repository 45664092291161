import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Level } from '../../../../types';

type LevelCardProps = Level & {
  onSelect: () => void;
};

export const LevelCard: React.FC<LevelCardProps> = ({ name, onSelect }) => {
  return (
    <Grid container direction="column" onClick={onSelect} sx={{ cursor: 'pointer' }}>
      <Typography variant="subtitle1">{name}</Typography>
    </Grid>
  );
};
