import { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { omit } from 'lodash';

export type UserSession = {
  id: string | null;
  token: string | null;
  email: string | null;
  name: string | null;
};

type AuthProviderValues = UserSession & {
  login?: (user: UserSession) => void;
  logout?: () => void;
  loading: boolean;
};

const defaultValue: AuthProviderValues = {
  id: null,
  token: null,
  email: null,
  name: null,
  loading: true,
};

const AuthContext = createContext(defaultValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [authState, setAuthState] = useState<UserSession>(omit(defaultValue, 'loading'));
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState.token && pathname !== '/login') {
      try {
        const fromLocalstorage = JSON.parse(localStorage.getItem('__user') ?? '{}');
        if (fromLocalstorage?.token && fromLocalstorage?.email && fromLocalstorage?.name) {
          setAuthState(fromLocalstorage);
        } else {
          navigate('/login');
        }
      } catch (e) {
        console.error(e);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    } else if (pathname === '/login' && authState.token) {
      setLoading(false);
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, pathname]);

  const login = useCallback((user: UserSession) => {
    localStorage.setItem('__user', JSON.stringify(user));
    setAuthState(user);
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('__user');
    setAuthState({
      id: null,
      token: null,
      email: null,
      name: null,
    });
    navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValues = useMemo(() => ({
    ...authState,
    login,
    logout,
    loading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [authState, loading]);

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  )
};
