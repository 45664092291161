import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { LevelsList } from './LevelsList';
import { useParams } from 'react-router';
import { useApi } from '../../lib/api';
import { useLoading } from '../../lib/hooks';
import { Level } from '../../types';
import { Blocker } from '../../components/Blocker';
import { LevelForm } from './LevelForm';
import { LevelsResponse } from './types';

export const LevelsPage: React.FC = () => {
  const [levels, setLevels] = useState<Level[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<Level | null>(null);
  const { id } = useParams<{ id: string }>();
  const { get } = useApi();

  const [loadLevels, loading] = useLoading(async () => {
    const response = await get(`/subject/${id}/levels`, {});
    if (response?.data) {
      setLevels((response?.data as LevelsResponse).levels);
    }
  }, [get, id]);

  useEffect(() => {
    loadLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchLevels = useCallback(async (level: Level) => {
    setSelectedLevel(null);
    await loadLevels();
    setSelectedLevel(level);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} sx={{ position: 'relative' }}>
      <Grid item xs={12} sm={4}>
        <LevelsList
          levels={levels}
          onLevelSelect={setSelectedLevel}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <LevelForm
          onSave={refetchLevels}
          subjectId={id ?? ''}
          selectedLevel={selectedLevel ?? undefined}
          key={selectedLevel?._id}
        />
      </Grid>
      <Blocker loading={loading} />
    </Grid>
  );
};
