import React from 'react';
import { ThemeProvider } from '@mui/material';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { theme } from './lib/theme/theme';
import { AuthProvider } from './lib/providers/AuthProvider';
import { LoginPage } from './pages/LoginPage';
import { LogoutPage } from './pages/LogoutPage';
import { MainLayout } from './components/MainLayout';
import { SubjectsPage } from './pages/SubjectsPage';
import { LevelsPage } from './pages/LevelsPage';
import { ExercisesPage } from './pages/ExercisesPage';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <MainLayout>
            <Routes>
              <Route path="/" element={<SubjectsPage />} />
              <Route path="/subject/:id" element={<LevelsPage />} />
              <Route path="/level/:id" element={<ExercisesPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
            </Routes>
          </MainLayout>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
