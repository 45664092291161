import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Exercise } from '../../../../types';

type ExerciseCardProps = Exercise & {
  onSelect: () => void;
};

export const ExerciseCard: React.FC<ExerciseCardProps> = ({ name, description, onSelect }) => {
  return (
    <Grid container direction="column" onClick={onSelect} sx={{ cursor: 'pointer' }}>
      <Typography variant="subtitle1">{name}</Typography>
      <Typography variant="caption">{description}</Typography>
    </Grid>
  );
};
