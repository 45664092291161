import React from 'react';
import { Divider, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { UseFormRegister, Control, UseFormSetValue } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { ExerciseFormData } from '../types';
import { MathConstructor } from './MathConstructor';
import { ListeningConstructor } from './ListeningConstructor';
import { ListeningTestConstructor } from './ListeningTestConstructor';

type StepCardProps = ExerciseFormData['steps'][number] & {
  register: UseFormRegister<ExerciseFormData>;
  control: Control<ExerciseFormData>;
  setValue: UseFormSetValue<ExerciseFormData>;
  removeStep: () => void;
  index: number;
};

export const StepCard: React.FC<StepCardProps> = ({
  type,
  content,
  register,
  index,
  control,
  removeStep,
  setValue,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container justifyContent="space-between">
        <Typography variant="subtitle1">
          Step #{index + 1}
        </Typography>
        <IconButton size="small" onClick={removeStep} color="warning">
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Select {...register(`steps.${index}.type`)} value={type} sx={{ width: '100%' }}>
          <MenuItem value="math">Math</MenuItem>
          <MenuItem value="listening">Listening</MenuItem>
          <MenuItem value="listening-test">Listening Test</MenuItem>
        </Select>
      </Grid>
      {type === 'math' && (
        <Grid item>
          <MathConstructor
            control={control}
            register={register}
            path={`steps.${index}.content`}
          />
        </Grid>
      )}
      {type === 'listening' && (
        <Grid item>
          <ListeningConstructor
            register={register}
            path={`steps.${index}.content`}
            setValue={setValue}
            audioUrl={content?.audioUrl}
          />
        </Grid>
      )}
      {type === 'listening-test' && (
        <Grid item>
          <ListeningTestConstructor
            control={control}
            register={register}
            path={`steps.${index}.content`}
            setValue={setValue}
            audioUrl={content?.audioUrl}
          />
        </Grid>
      )}
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};
