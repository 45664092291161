import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Level } from '../../../types';
import { LevelCard } from './LevelCard';

type LevelsListProps = {
  levels: Level[];
  onLevelSelect: (level: Level | null) => void;
};

export const LevelsList: React.FC<LevelsListProps> = ({ levels, onLevelSelect }) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Levels list
            </Typography>
          </Grid>
          {levels.map((level) => (
            <Grid item key={level._id}>
              <LevelCard {...level} onSelect={() => onLevelSelect(level)} />
            </Grid>
          ))}
          <Grid item>
            <Button
              onClick={() => onLevelSelect(null)}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Create new level
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
