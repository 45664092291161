import React from 'react';
import { Button, Grid, TextField, FormLabel } from '@mui/material';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { ExerciseFormData } from '../../types';

type MathConstructorProps = {
  control: Control<ExerciseFormData>;
  register: UseFormRegister<ExerciseFormData>;
  path: string;
};

export const MathConstructor: React.FC<MathConstructorProps> = ({ control, register, path }) => {
  const { fields: ops, append: addOpp, remove: removeOpp } = useFieldArray({
    control,
    name: `${path}.ops` as 'steps',
    shouldUnregister: true,
  });

  const { fields: variants, append: addVariant, remove: removeVariant } = useFieldArray({
    control,
    name: `${path}.variants` as 'steps',
    shouldUnregister: true,
  });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormLabel>
          Operations
        </FormLabel>
      </Grid>
      <Grid item container spacing={2}>
        {ops.map((field, index) => (
          <Grid item key={field.id} sm={2}>
            <TextField
              {...register(`${path}.ops.${index}.value` as 'steps.0.type')}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => addOpp({ value: '' } as any)}
          type="button"
        >
          Insert operation
        </Button>
        {ops.length > 0 && (
          <Button
            variant="outlined"
            color="warning"
            onClick={() => removeOpp(ops.length - 1)}
            type="button"
          >
            Remove last operation
          </Button>
        )}
      </Grid>

      <Grid item>
        <FormLabel>
          Variants
        </FormLabel>
      </Grid>
      <Grid item container spacing={2}>
        {variants.map((field, index) => (
          <Grid item key={field.id} sm={2}>
            <TextField
              {...register(`${path}.variants.${index}.value` as 'steps.0.type')}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => addVariant({ value: '' } as any)}
          type="button"
        >
          Insert variant
        </Button>
        {variants.length > 0 && (
          <Button
            variant="outlined"
            color="warning"
            onClick={() => removeVariant(variants.length - 1)}
            type="button"
          >
            Remove last variant
          </Button>
        )}
      </Grid>
      <Grid item>
        <TextField
          label="Correct answer"
          {...register(`${path}.right` as 'steps.0.type')}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
};
