import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SubjectsList } from './SubjectsList';
import { SubjectForm } from './SubjectForm';
import { useApi } from '../../lib/api';
import { useLoading } from '../../lib/hooks';
import { Subject } from '../../types';
import { Blocker } from '../../components/Blocker';

export const SubjectsPage: React.FC = () => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<Subject | null>(null);
  const { get } = useApi();
  const [loadSubjects, loading] = useLoading(async () => {
    const response = await get('/subject/list', {});
    if (response?.data) {
      setSubjects(response?.data as Subject[]);
    }
  }, [get]);

  useEffect(() => {
    loadSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchSubjects = useCallback(async (subject: Subject) => {
    setSelectedSubject(null);
    await loadSubjects();
    setSelectedSubject(subject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} sx={{ position: 'relative' }}>
      <Grid item xs={12} sm={4}>
        <SubjectsList
          subjects={subjects}
          onSubjectSelect={setSelectedSubject}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <SubjectForm
          onSave={refetchSubjects}
          selectedSubject={selectedSubject ?? undefined}
          key={selectedSubject?._id}
        />
      </Grid>
      <Blocker loading={loading} />
    </Grid>
  );
};
