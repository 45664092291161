import React, { useEffect, useState } from 'react';
import { Alert, Button, Chip, Grid, Snackbar, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { ExerciseFormData } from '../../types';
import { useLoading } from '../../../../../lib/hooks';
import { useApi } from '../../../../../lib/api';
import { Blocker } from '../../../../../components/Blocker';

type ListeningConstructorProps = {
  register: UseFormRegister<ExerciseFormData>;
  path: string;
  setValue: UseFormSetValue<ExerciseFormData>;
  audioUrl?: string;
};

export const ListeningConstructor: React.FC<ListeningConstructorProps> = ({ register, path, setValue, audioUrl }) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const { post } = useApi();

  const [handleUpload, uploading] = useLoading(async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('category', 'audio');
      const res = await post('/admin/files/upload', formData);
      if (res?.data?.filePath) {
        setValue(`${path}.audioUrl` as 'steps', res.data.filePath);
      } else {
        setError('Uploading error');
      }
    } catch (e) {
      console.error(e);
      setError('Uploading error');
    }
  }, [path]);

  useEffect(() => {
    if (file) {
      handleUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Grid container direction="column" spacing={2}>
      <input {...register(`${path}.audioUrl` as 'steps')} type="hidden" />
      <Grid item>
        <TextField
          {...register(`${path}.text` as 'steps', { required: true })}
          size="medium"
          variant="standard"
          label="Text"
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item container justifyContent="center" alignItems="center">
        {audioUrl ? (
          <Chip icon={<CheckCircleIcon />} label="Audio uploaded" color="success" sx={{ mr: 2 }} />
        ) : (
          <Typography
            variant="caption"
            sx={(theme) => ({
              mr: 2,
            })}
          >
            Please select audio file
          </Typography>
        )}
        <Button variant="contained" component="label" color="secondary">
          Upload audio
          <input
            hidden
            accept="audio/*"
            multiple
            type="file"
            onChange={(event) => setFile(event.target.files?.[0])}
          />
        </Button>
      </Grid>
      <Grid item>
        <TextField
          {...register(`${path}.speed` as 'steps', { required: true })}
          size="medium"
          variant="standard"
          label="Speed"
          sx={{ width: '100%' }}
          type="number"
        />
      </Grid>
      <Blocker loading={uploading} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!error}
        onClose={() => setError(null)}
        autoHideDuration={6000}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
