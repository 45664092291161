import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Subject } from '../../../../types';

type SubjectCardProps = Subject & {
  onSelect: () => void;
};

export const SubjectCard: React.FC<SubjectCardProps> = ({ name, description, onSelect }) => {
  return (
    <Grid container direction="column" onClick={onSelect} sx={{ cursor: 'pointer' }}>
      <Typography variant="subtitle1">{name}</Typography>
      <Typography variant="caption">{description}</Typography>
    </Grid>
  );
};
