import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ExercisesList } from './ExercisesList';
import { Exercise } from '../../types';
import { useParams } from 'react-router';
import { useApi } from '../../lib/api';
import { useLoading } from '../../lib/hooks';
import { Blocker } from '../../components/Blocker';
import { ExerciseForm } from './ExerciseForm';
import { LevelResponse } from './types';

export const ExercisesPage: React.FC = () => {
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [selectedExercise, setSelectedExercise] = useState<Exercise | null>(null);
  const { id } = useParams<{ id: string }>();
  const { get } = useApi();

  const [loadExercises, loading] = useLoading(async () => {
    const response = await get(`/level/${id}/exercises`, {});
    if (response?.data) {
      setExercises((response?.data as LevelResponse).exercises);
    }
  }, [get, id]);

  useEffect(() => {
    loadExercises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchExercises = useCallback(async (exercise: Exercise) => {
    setSelectedExercise(null);
    await loadExercises();
    setSelectedExercise(exercise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} sx={{ position: 'relative' }}>
      <Grid item xs={12} sm={4}>
        <ExercisesList
          exercises={exercises}
          onExerciseSelect={setSelectedExercise}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <ExerciseForm
          onSave={refetchExercises}
          levelId={id ?? ''}
          selectedExercise={selectedExercise ?? undefined}
          key={selectedExercise?._id}
        />
      </Grid>
      <Blocker loading={loading} />
    </Grid>
  );
};
