import React, { useEffect, useState } from 'react';
import { omit, pick } from 'lodash';
import { Alert, Button, Card, CardContent, css, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLoading } from '../../../lib/hooks';
import { useApi } from '../../../lib/api';
import { Blocker } from '../../../components/Blocker';
import { Subject } from '../../../types';
import { config } from '../../../config';
import { Link } from 'react-router-dom';

type SubjectFormData = Omit<Subject, 'icon' | 'id'>;
type SubjectFormProps = {
  selectedSubject?: Subject;
  onSave: (subject: Subject) => void;
};

export const SubjectForm: React.FC<SubjectFormProps> = ({ selectedSubject, onSave }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<SubjectFormData>({
    defaultValues: omit(selectedSubject ?? {}, ['_id', 'icon']),
  });
  const [file, setFile] = useState<File | undefined>(undefined);
  const [uploadedFile, setUploadedFile] = useState<string | null>(
    selectedSubject ? selectedSubject.icon : null
  );
  const [error, setError] = useState<string | null>(null);
  const { post } = useApi();

  const [handleSubjectSave, loading] = useLoading(async (data: SubjectFormData) => {
    try {
      if (!uploadedFile) {
        setError('Please upload the icon');
        return;
      }

      if (selectedSubject?._id) {
        const updated = await post(`/admin/subject/${selectedSubject._id}/edit`, {
          ...pick(data, ['name', 'description', 'color', 'order']),
          icon: uploadedFile,
        });
        reset();
        if (updated?.data) {
          onSave(updated.data as Subject);
        }
      } else {
        const subject = await post('/admin/subject/create', {
          ...pick(data, ['name', 'description', 'color', 'order']),
          icon: uploadedFile,
        });
        reset();

        if (subject?.data) {
          onSave(subject?.data as Subject);
        }
      }
    } catch (e) {
      console.error(e);
      setError('Something went wrong');
    }
  }, [uploadedFile, onSave, selectedSubject?._id]);

  const [handleUpload, uploading] = useLoading(async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('category', 'images');
      const res = await post('/admin/files/upload', formData);
      if (res?.data?.filePath) {
        setUploadedFile(res.data.filePath);
      } else {
        setError('Uploading error');
      }
    } catch (e) {
      console.error(e);
      setError('Uploading error');
    }
  }, []);

  useEffect(() => {
    if (file) {
      handleUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit(handleSubjectSave)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">
                {selectedSubject?.name ? `Edit subject: ${selectedSubject?.name}` : 'Subject'}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                {...register('name', { required: true })}
                size="medium"
                variant="standard"
                label="Name"
                sx={{ width: '100%' }}
                error={!!errors.name}
              />
            </Grid>
            <Grid item>
              <TextField
                {...register('description')}
                size="medium"
                variant="standard"
                label="Description"
                sx={{ width: '100%' }}
                multiline
                error={!!errors.description}
              />
            </Grid>
            <Grid item>
              <TextField
                {...register('color', { required: true })}
                size="medium"
                variant="standard"
                label="Color"
                sx={{ width: '100%' }}
                error={!!errors.color}
              />
            </Grid>
            <Grid item>
              <TextField
                {...register('order')}
                size="medium"
                variant="standard"
                label="Order"
                sx={{ width: '100%' }}
                error={!!errors.order}
                type="number"
              />
            </Grid>
            <Grid item container justifyContent="center" alignItems="center">
              {uploadedFile ? (
                <img
                  src={`${config.filesStorage}${uploadedFile}`}
                  width={80}
                  css={(theme) => css`
                    margin-right: ${theme.spacing(2)};
                  `}
                  alt="subject icon"
                />
              ) : (
                <Typography
                  variant="caption"
                  sx={(theme) => ({
                    mr: 2,
                  })}
                >
                  Please select icon
                </Typography>
              )}
              <Button variant="contained" component="label" color="secondary">
                Upload icon
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(event) => setFile(event.target.files?.[0])}
                />
              </Button>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Button variant="contained" type="submit">
                Save
              </Button>
              {!!selectedSubject?._id && (
                <Link to={`/subject/${selectedSubject?._id}`}>
                  <Button variant="text" component="span">
                    Manage levels
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </form>
        <Blocker loading={loading || uploading} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!!error}
          onClose={() => setError(null)}
          autoHideDuration={6000}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};
