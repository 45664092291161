import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Exercise } from '../../../types';
import { ExerciseCard } from './ExerciseCard';

type ExercisesListProps = {
  exercises: Exercise[];
  onExerciseSelect: (exercise: Exercise | null) => void;
};

export const ExercisesList: React.FC<ExercisesListProps> = ({ exercises, onExerciseSelect }) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Exercises list
            </Typography>
          </Grid>
          {exercises.map((exercise) => (
            <Grid item key={exercise._id}>
              <ExerciseCard {...exercise} onSelect={() => onExerciseSelect(exercise)} />
            </Grid>
          ))}
          <Grid item>
            <Button
              onClick={() => onExerciseSelect(null)}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Create new exercise
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
