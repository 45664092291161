import { ExerciseFormData } from '../types';
import { Exercise } from '../../../../types';

export const formStateToInput = (data: ExerciseFormData): Omit<Exercise, '_id' | 'level'> => {
  return {
    ...data,
    steps: data.steps.map((step) => {
      if (step.type === 'math') {
        return {
          ...step,
          content: {
            ops: step.content.ops.map((op) => op.value),
            variants: step.content.variants.map((op) => op.value),
            right: step.content.right,
          }
        };
      }

      if (step.type === 'listening-test') {
        return {
          ...step,
          content: {
            ...step.content,
            variants: step.content.variants.map((op) => op.value),
          }
        };
      }

      return step;
    }),
  }
};

export const inputToFormState = (input: Exercise): ExerciseFormData => {
  return {
    ...input,
    steps: input.steps.map((step) => {
      if (step.type === 'math') {
        return {
          ...step,
          content: {
            ops: step.content.ops.map((val) => ({ value: val })),
            variants: step.content.variants.map((val) => ({ value: val })),
            right: step.content.right,
          }
        };
      }

      if (step.type === 'listening-test') {
        return {
          ...step,
          content: {
            ...step.content,
            variants: step.content.variants.map((val) => ({ value: val })),
          }
        };
      }

      return step;
    })
  };
}
