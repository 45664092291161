import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#8c7ae6',
    },
    secondary: {
      main: '#7f8fa6',
    }
  },
});
