import React, { useState } from 'react';
import { Level } from '../../../types';
import { useForm } from 'react-hook-form';
import { omit, pick } from 'lodash';
import { useApi } from '../../../lib/api';
import { useLoading } from '../../../lib/hooks';
import { Alert, Button, Card, CardContent, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Blocker } from '../../../components/Blocker';

type LevelFormData = Omit<Level, 'id'>;
type LevelFormProps = {
  selectedLevel?: Level;
  onSave: (level: Level) => void;
  subjectId: string;
};

export const LevelForm: React.FC<LevelFormProps> = ({ selectedLevel, onSave, subjectId }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<LevelFormData>({
    defaultValues: omit(selectedLevel ?? {}, ['_id']),
  });

  const [error, setError] = useState<string | null>(null);
  const { post } = useApi();

  const [handleLevelSave, loading] = useLoading(async (data: LevelFormData) => {
    try {
      if (selectedLevel?._id) {
        const updated = await post(`/admin/level/${selectedLevel._id}/edit`, {
          ...pick(data, ['name']),
        });
        reset();
        if (updated?.data) {
          onSave(updated.data as Level);
        }
      } else {
        const subject = await post('/admin/level/create', {
          ...pick(data, ['name']),
          subject: subjectId,
        });
        reset();

        if (subject?.data) {
          onSave(subject?.data as Level);
        }
      }
    } catch (e) {
      console.error(e);
      setError('Something went wrong');
    }
  }, [subjectId, onSave, selectedLevel?._id]);

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit(handleLevelSave)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">
                {selectedLevel?.name ? `Edit level: ${selectedLevel?.name}` : 'Level'}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                {...register('name', { required: true })}
                size="medium"
                variant="standard"
                label="Name"
                sx={{ width: '100%' }}
                error={!!errors.name}
              />
            </Grid>
            <Grid item container justifyContent="space-between">
              <Button variant="contained" type="submit">
                Save
              </Button>
              {!!selectedLevel?._id && (
                <Link to={`/level/${selectedLevel?._id}`}>
                  <Button variant="text" component="span">
                    Manage exercises
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </form>
        <Blocker loading={loading} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!!error}
          onClose={() => setError(null)}
          autoHideDuration={6000}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
};
