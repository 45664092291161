import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Subject } from '../../../types';
import { SubjectCard } from './SubjectCard';

type SubjectsListProps = {
  subjects: Subject[];
  onSubjectSelect: (subject: Subject | null) => void;
};

export const SubjectsList: React.FC<SubjectsListProps> = ({ subjects, onSubjectSelect }) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Subjects list
            </Typography>
          </Grid>
          {subjects.map((subject) => (
            <Grid item key={subject._id}>
              <SubjectCard {...subject} onSelect={() => onSubjectSelect(subject)} />
            </Grid>
          ))}
          <Grid item>
            <Button
              onClick={() => onSubjectSelect(null)}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Create new subject
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
